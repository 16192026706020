import React from "react";
import { Link } from "gatsby";
import Layout from "~components/base/layout";
import SEO from "~components/base/seo";
import Accordion from "~components/accordion/accordion";
import Products from "~components/products/products";
import "~styles/pages/services/_website.scss";
import Image from "../../components/base/image";
import TypeAnimation from "react-type-animation";

const ShelfWebsitesPage = () => (
  <Layout type="template-website">
    <SEO
      title="Off The Shelf Website Design & Development"
      description="Looking for straightforward and affordable off-the-shelf web design and development? Simply choose a package to suit your budget and let's get building! "
    />
    <section className="page-heading">
      <div className="container">
        <TypeAnimation
          cursor={false}
          sequence={["Off-the-Shelf Websites", 500]}
          className={"title"}
          wrapper="h1"
        />
        <div className="website-header">
          <div className="sample-list">
            <a href="https://www.bcli.org/" target="_blank" rel="noreferrer">
              <Image
                filename="HOTSOUP_LAB_BCLI.jpg"
                alt="Product Photography"
              />
            </a>
            <a
              href="https://theacquisitiongroup.com/"
              target="_blank"
              rel="noreferrer"
            >
              <Image filename="HOTSOUP_LAB_TAG.jpg" alt="Product Photography" />
            </a>
            <a
              href="http://hiporecruiting.ca/"
              target="_blank"
              rel="noreferrer"
            >
              <Image
                filename="HOTSOUP_LAB_HIPO.jpg"
                alt="Product Photography"
              />
            </a>
          </div>
          <p>
            Do you need help creating a simple website that works? Not everyone
            needs a complex, expensive website that takes months to build.
            Sometimes you just need something straightforward, affordable and
            effective. We design and build straightforward websites that look
            good, work well, and get your business found. Ready to get started?
            Simply choose a package to suit your budget and let’s get building!
             
          </p>
        </div>
      </div>
    </section>
    <section>
      <div className="container red-accordion">
        <Accordion>
          <div label="Web Kit 1 | $5,525 CAD - $7,650 CAD " subtitle="">
            <p>
              No commerce, no complex integrations – a website to help you be
              found, explain what you do and what makes you special. We will
              present you with 3 home page design concepts. You choose 1. We
              build out your complete site. Includes 60-day post-build webmaster
              support.
            </p>
            <Products type="slim">
              <div
                title="Web Kit 1.0 | $5,525 CAD"
                id="website-opt1-basic"
                subtitle="Everything but the Content"
                extrainfo="You provide us with the complete site copy, content, images &
                brand assets and we build you a basic 6-8 page site, including
                a blog template and sitemap."
              ></div>
              <div
                title="Web Kit 1.1 | $7,650 CAD"
                id="website-opt1-pluscontent"
                subtitle="Add in Words"
                extrainfo="The Web Kit 1.0 package above, but our talented copywriting team will handle the complete, SEO-friendly copy for your entire 6-8 page site at the beginning of the process."
              ></div>
            </Products>
          </div>
          <div label="Web Kit 2 | $12,750 CAD - $17,000 CAD" subtitle="">
            <p>
              Simple commerce, but no complex CRM, marketing automation, or
              integrations outside of your payment portal. We will present you
              with 3 home page design concepts, along with a user journey map.
              You choose 1 design concept and sign off on the journey. We build.
              Includes 60-day post-build webmaster support.
            </p>
            <Products type="slim">
              <div
                title="Web Kit 2.0 | $12,750 CAD"
                id="website-opt2-basic"
                subtitle="Everything but the Content"
                extrainfo="You provide us with the complete site copy, content, images & brand assets and we build. We will create all the commerce categories, pages needed, blog template, sitemap, shipping options, tax setup, and product templates, but you will be responsible for adding all your products, including images & descriptions."
              ></div>
              <div
                title="Web Kit 2.1 | $17,000 CAD"
                id="website-opt2-complete"
                subtitle="Add in Smart Words+"
                extrainfo="The Web Kit 2.0 package above, but our talented copywriting team will handle the complete, SEO-friendly copy for your entire site, including 15 product descriptions, at the beginning of the process. Additional product descriptions can also be purchased. We will add all your products, including descriptions & images to your site. You provide us with the info, images, product & price list and we’ll give you back and complete, SEOptimized ecommerce site."
              ></div>
            </Products>
          </div>
          <div label="Web Kit 3 | $30,000 CAD - $40,000 CAD" subtitle="">
            <p>
              Whether it is Shopify, WooCommerce, BigCommerce, Magento, Commerce
              Cloud (Salesforce B2C/B2B Commerce) HubSpot CMS, WebFlow or a web
              platform based entirely in REACT (Gatsby JS for example) - this
              level of undertaking is for a business that is serious about
              scaling their online sales and lead generation. The reason these
              projects have a dramatically higher budget range is because of the
              amount of planning/mapping and the level of fine tuning multiple
              layers of technology to be optimized to your business and sales
              model. This could mean having a wholesale channel as well as a
              consumer channel for your site - or it could mean integrating
              multiple Google Product APIs into your platform to create a
              complete experience for your guests (for example, helping them
              find the closest retailer while showing live inventory levels and
              pricing).<br></br>You are only doing this level of investment if
              your website can directly generate AT LEAST $500,000 in annual
              revenue in sales or leads. This would and should be the most
              complex type of web project you should engage with us on. Anything
              beyond this requires a much different type of project/product
              management, meaning you will want the support that comes with all
              of the headaches of a bigger agency.
            </p>
            <Products type="slim">
              <div
                title="Web Kit 3.0 | $30,000 CAD"
                id="website-opt3-basic"
                subtitle="Everything but the Content."
                extrainfo="We will need at least the site copy outline, content, images and brand assets to help us accurately map out the web experience, but we also need to understand the business processes, software, platforms, CRM, etc... that this website will be, or you want to have talking to in order to make the most of your MQL (Marketing Qualified Leads) and SQL (Sales Qualified Leads) visitors. More importantly, to make the most of this new asset, you should have a fairly strong content marketing plan as making the most of a website like this, means publishing, distributing and promoting your website content to bring traffic to the platform. We would also recommend that you are at least thinking about an annual budget line item that is dedicated to continually adding to and making the website even more productive for you."
              ></div>
              <div
                title="Web Kit 3.1 | $40,000 CAD"
                id="website-opt3-complete"
                subtitle="Add in Smart Words+"
                extrainfo="For this level of scope, we will be working closely with your team to truly ensure that your brand voice and tone are coming through loud and clear across every pixel on your website. Going beyond commodity based keywords and optimization, a project of this level is really about brand building and perception setting, so much of our time is also going into how we help you plant and prioritize future content for the website to ensure that this beautiful new toy does not collect digital dust."
              ></div>
            </Products>
          </div>
        </Accordion>
      </div>
    </section>
    <section>
      <div className="container">
        <div className="website-addons p-mb">
          <h2>Website Add-Ons</h2>
          <p>
            A big reason why a website, is not a website, is not a website – is
            because the people who are building it, have a huge impact on the
            final results. More importantly, how much care, attention to detail
            and how much the brand is coming to life – will also dramatically
            impact the final result. Think of your favourite brand of denim. Now
            think of denim that is either way cheaper, or way more expensive.
          </p>
          <p>
            <strong>Basically, the same thing.</strong> But the way they look,
            fit, feel, how they wear over time – all of that comes from the fact
            that a different approach, methodology and skill level were used to
            make essentially the same product. These scope-additions are ways to
            take that web project even further and make that final result feel
            special, tailored and unique feeling and looking.
          </p>
        </div>
        <div className="blue-outline website-addonlist-wrapper">
          <Accordion>
            <div label="Visual Language Package" subtitle="$1,870 CAD">
              <p>
                Our visual language package is designed to build out your brand
                if you’ve got a logo and not much else. Includes a Visual
                Language Guide, templates for social, a collection of stock
                images & graphics, and your choice of 2 communication templates
                (please see product description for more details). Does not
                include logo design. Want to see the impact of visual language
                on a website? <Link to="#faq">Click here.</Link>
                <br></br>
                <Link
                  className="btn btn-dark"
                  to="/services/addons/visual-language-package"
                >
                  LEARN MORE
                </Link>
              </p>
            </div>
            <div label="CRM Setup & Integration" subtitle="$850 CAD">
              <p>
                Want a CRM but unsure where to start? Our inbound specialist
                will work with you to find the best CRM fit for your business,
                get the account set up for you, and then work with our web team
                to ensure smooth integration between your website and new CRM.
                This package does not include the cost of the CRM or any paid
                plugins needed.<br></br>
                <Link
                  className="btn btn-dark"
                  to="/services/addons/crm-setup-integration"
                >
                  LEARN MORE
                </Link>
              </p>
            </div>
            <div label="Integrations Package" subtitle="By Request">
              <p>
                Got some complex integrations you need help with?{" "}
                <a
                  href="https://hsmgsurveys.typeform.com/to/iYyJ4mlb"
                  target="_blank"
                  rel="noreferrer"
                >
                  Fill out this form
                </a>{" "}
                to give us a better sense of what you need to accomplish, and
                our integrations team will get back to you with a custom scope
                of work and quote.<br></br>
                <Link
                  to="/services/addons/integrations-package"
                  className="btn btn-dark"
                >
                  LEARN MORE
                </Link>
              </p>
            </div>
          </Accordion>
        </div>
      </div>
    </section>
    <section id="faq" className="faq-wrapper">
      <div className="container">
        <h2>FAQ</h2>
        <Accordion>
          <div label="What is a Visual Language?">
            <p>
              Visual language is a lot more than a logo and some brand colours.
              Often it gets lumped into the creation of your brand identity and
              is usually further defined when brand assets such as business
              cards, presentations, social graphics and more are created. Visual
              language makes a huge impact on how your website looks and feels.
              For example,{" "}
              <a
                href="https://theacquisitiongroup.com/"
                target="_blank"
                rel="noreferrer"
              >
                The Acquisition Group
              </a>{" "}
              is a website we created with a strong visual language, and{" "}
              <a href="https://inclera.com/" target="_blank" rel="noreferrer">
                Inclera
              </a>{" "}
              is a website where the client did not have an established visual
              language. Both sites are similar in terms of number of pages,
              amount and type of content, structure, and tech build – but they
              look and feel vastly different. Want a visual language package?
              Make sure to add one to your cart during checkout.
            </p>
          </div>
          <div label="Why do we hate Shopify?">
            <p>
              It’s not that we hate it. We hate that you are asking us to work
              on it and manage it. Shopify was created so that small business
              owners could quickly build out and manage their own e-commerce
              space – and in order to do that, it gives you a very small
              sandbox. Often clients come to us because they’re trying to make
              Shopify do something it was not built to do. We can definitely do
              it and we have probably created a few million-dollar plugin ideas
              along the way, but Shopify is meant to be done without the need
              for an agency and help you test your idea – BEFORE you work with
              an agency to really grow it into something big. Do you think you
              will exceed $1,000,000 in annual sales? Start to really think
              about your margins and the total tech costs in operating a fully
              functional Shopify store. Think you will break through $5,000,000
              in annual sales? You should be avoiding Shopify like the plague as
              it will become an operational and marketing liability – rather
              than an asset. The craziest thing – you are getting pathetic
              access to your own data – but it is not your own data, you are
              just renting it from Shopify!
            </p>
          </div>
        </Accordion>
      </div>
    </section>
  </Layout>
);

export default ShelfWebsitesPage;
